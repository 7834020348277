<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
    <!-- <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/hanh/components/img/1_1.png";
import importedImg2 from "@/page/hanh/components/img/1_2.png";
import importedImg3 from "@/page/hanh/components/img/1_3.png";
import importedImg4 from "@/page/hanh/components/img/1_4.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
importedImg4: importedImg4,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
/* .imgBox1 {
  margin-top: 100px;
} */
.imgBox1,.imgBox2,.imgBox4 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}


.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1,.img2,.img4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}


.img3 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,.imgItem2,.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2,.imgItem3 {
  max-width: 100%;
}
.imgItem2 {
  margin-bottom: 100px;
}
.imgItem3 {
  margin-bottom: 100px;
}

/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */
</style>
